import React from 'react'
import Nav from './Nav'
import Footer from './Footer'

const Policies = () => {
  return (
    <>
    <Nav />
  
        <div className='policy-box'>
        <h1 className='policy-tittle'>Políticas de Privacidad de QSocialNow</h1>
        
<h3 className='policy-text'> 
    1- QSocialNow se compromete a proteger la privacidad de sus usuarios y clientes, asegurando que sus datos personales sean manejados de manera segura y responsable. <br/>

2 - QSocialNow recolecta información personal cuando los usuarios interactúan con sus servicios. Esto incluye datos de contacto, información de la cuenta y otros datos necesarios para proporcionar sus servicios. <br/>

3 - Los datos recolectados se utilizan para mejorar los servicios ofrecidos, personalizar la experiencia del usuario y para comunicaciones relacionadas con los servicios de QSocialNow.
<br/>
4- QSocialNow no comparte datos personales con terceros sin el consentimiento del usuario, salvo cuando sea necesario para cumplir con obligaciones legales o para proteger la seguridad de sus usuarios.
<br/>
5 - QSocialNow implementa medidas de seguridad adecuadas para proteger los datos personales contra acceso no autorizado, alteración, divulgación o destrucción.
<br/>
6 -  Los usuarios tienen derecho a acceder, rectificar y eliminar sus datos personales, así como a restringir o oponerse al procesamiento de sus datos bajo ciertas circunstancias.
<br/>
7 - Para cualquier consulta o solicitud relacionada con la privacidad, los usuarios pueden ponerse en contacto con QSocialNow a través de los medios proporcionados en su sitio web.
 <br/>
 
 </h3>
    </div>
    
    </>
  )
}

export default Policies
