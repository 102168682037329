/** @format */

import React from "react";
import Nav from "./../Componentes/Nav";
import "./index.css";
import { Button, Input } from "antd";
import { Link } from "react-router-dom";
import Home7 from './Home7'

export default function AnaliticaAvanzada() {
  return (
    <div className="contenedor-analiticaAvanzada" id="inicio">
      <Nav />
      <div className="titulosubtituloboton">
        <div className="titulo-pagina"><strong>Analítica Avanzada</strong></div>

        <div className="subtitulo-pagina-analiticaAvanzada">
          Trabajamos en un{" "}
          <strong>
            {" "}
            enfoque que analiza la dinámica de la opinión pública combinando{" "}
          </strong>{" "}
          la dimensión racional con la emocional. Aplicando nuestros recursos de{" "}
          <strong> inteligencia artificial </strong>, abordamos la complejidad
          que significa en la actualidad el estudio de las{" "}
          <strong> percepciones y valoraciones ciudadanas </strong> a través de
          más de <strong> 150 categorías </strong> de análisis integradas a una
          multiplicidad de Modelos Analíticos, todos ellos actualizados en
          tiempo real. <strong> Entre ellos: </strong>
        </div>
      </div>

      <div className="contenedor-cartas-analiticaAvanzada">
        <div className="carta-analiticaavanzada">
          <strong>Preocupaciones Generales e Intereses Ciudadanos</strong>{" "}
          <br />
          (8 categorías)
        </div>

        <div className="carta-analiticaavanzada">
          <strong>Preocupaciones Locales</strong>{" "}
          <br />
          (7 categorías)
        </div>

        <div className="carta-analiticaavanzada">
          <strong>Análisis del Clima de Época</strong>{" "}
          <br />
          (26 categorías)
        </div>

        <div className="carta-analiticaavanzada">
          <strong>Emociones y Sentimientos</strong>{" "}
          <br />
          (32 categorías)
        </div>

        <div className="carta-analiticaavanzada">
          <strong>Atributos del Liderazgo</strong>{" "}
          <br />
          (40 categorías)
        </div>

        <div className="carta-analiticaavanzada">
          <strong>Motivación del Voto</strong>{" "}
          <br />
          (12 categorías)
        </div>

        <div className="carta-analiticaavanzada">
          <strong>Imagen Social y Política</strong>{" "}
          <br />
          (18 categorías)
        </div>
      </div>

      <div className="nube-analiticaavanzada">
        <div className="texto-nube-analitica">
          Los recursos de la <strong> Analítica Avanzada </strong> permiten
          acceder a un estudio de
          <strong> corto, mediano y largo plazo </strong> sobre el{" "}
          <strong> comportamiento dinámico de la opinión pública </strong> e
          identificar la composición de las distintas
          <strong> audiencias que la integran.</strong>
        </div>
      </div>

      <div className="contenedor-texto-quienessomos">
        <div
          className="titulo-home5-analiticaavanzada"
     >
          ¿Cómo estudiamos la dinámica de la opinión pública y sus audiencias en
          el curso de un proceso electoral?
        </div>
      </div>

      <div className="contenedor-home5-analiticaAvanzada">
      <div className="gif-analiticaAvanzada">
        <img
          style={{ width: "95%", borderRadius: "3rem", marginBottom:'5%' }}
          src='https://qsocialnow.com/static/Imagenes/Dinamica-opinion-publica-electoral2.gif'
        />
      </div>

    
        <div className="titulo-home5"><strong>La conformación del escenario</strong></div>

        <div>
          <div className="subtitulo-home5-analiticaAvanzada">
            Nuestras tecnologías, asimismo, trabajan en la <strong> detección, análisis y
            predicción de las macro-tendencias proyectadas al territorio local,</strong>
            que modelan el contexto general en el que se desarrollará el proceso
            pre-electoral y electoral.
          </div>
            <br/>
          <div className="subtitulo-home5-analiticaAvanzada">
            Y, simultáneamente, se hace lo propio con las <strong> micro-tendencias que
            representan la especificidad de los temas y preocupaciones </strong>propias
            de la población de un territorio, determinado el contexto local.
          </div>
        </div>
    

      <div className="gif-analiticaAvanzada">
        <img
          style={{ width: "95%", borderRadius: "3rem" }}
          src='https://qsocialnow.com/static/Imagenes/Territorio.-04-09gif.gif'
        />
      </div>
      </div>

      <div className="titulo-home5"><strong>Grafos Interactivos</strong></div>

      <div className="grafos-interactivos">
    <Button  className="boton-mexico-analisisAvanzado" ><Link to='https://qsocialnow.com/static/Imagenes/grafo_hashtags_menciones.html' target="_blank">Pantalla Completa</Link></Button>
      </div>


   <Home7/>

    </div>
  );
}
