import { Button, Form, Input, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import './index.css';

const publicKey = 'WPvEUtAC2eoilS8Hn'; // Nueva clave pública proporcionada

export default function FormularioContacto() {
  const [messageApi, contextHolder] = message.useMessage();
  const emailCorporativo = 'no-reply@qsocialnow.com';
  const formRef = useRef(null);
  const [errorMail, setErrorMail] = useState("");
  const [successMail, setSuccessMail] = useState("");

  const success = (mensaje) => {
    messageApi.open({
      type: 'success',
      content: mensaje,
    });
  };

  const sendFeedback = async (serviceID, templateId, variables) => {
    try {
      const res = await window.emailjs.send(serviceID, templateId, variables, publicKey);
      console.log('Email successfully sent!', res);
      setSuccessMail(`Gracias por mandarnos un mail ${variables.from_name}, nos pondremos en contacto.`);
    } catch (err) {
      console.error('Error sending email:', err);
      setErrorMail('Hubo un error al enviar el formulario. Por favor, inténtalo nuevamente.');
      throw err;
    }
  };

  useEffect(() => {
    if (errorMail !== "") {
      messageApi.error(errorMail);
      setErrorMail("");
    }

    if (successMail !== "") {
      success(successMail);
      setSuccessMail("");
    }
  }, [errorMail, successMail, messageApi, success]);

  const onFinish = async (data, r) => {
    const templateId = 'template_n60t7a2';
    const serviceID = 'service_urqxzpw';

    const messageContent = `Nombre: ${data.name}\nTeléfono: ${data.telefono}\nEmail: ${data.email}\nMensaje: ${data.mensaje}`;

    try {
      await sendFeedback(serviceID, templateId, {
        from_name: data.name,
        message_html: messageContent,
        reply_to: emailCorporativo,
      });

      r.target.reset();
      formRef.current.resetFields();
    } catch (error) {
      messageApi.error('Hubo un error al enviar el formulario. Por favor, inténtalo nuevamente.');
    }
  };

  return (
    <div className='contenedor-formulario'>
      <div>Contacto</div>
      <Form name='Contacto' onFinish={onFinish} ref={formRef}>
        {contextHolder}
        <Form.Item
          name='name'
          rules={[
            {
              required: true,
              message: 'Por favor escribí tu nombre',
            },
          ]}
        >
          <Input className='input-oscuro-footer' placeholder='Nombre y Apellido' />
        </Form.Item>

        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Por favor escribí tu email',
            },
          ]}
        >
          <Input className='input-oscuro-footer' placeholder='Correo electronico' />
        </Form.Item>

        <Form.Item
          name='telefono'
          rules={[{ required: true, message: 'Por favor escribí tu número de teléfono' }]}
        >
          <Input className='input-oscuro-footer' placeholder='Teléfono' />
        </Form.Item>

        <Form.Item
          name='mensaje'
          rules={[{ required: true, message: 'Por favor escribí un mensaje' }]}
        >
          <TextArea className='input-oscuro-footer' placeholder='Mensaje' />
        </Form.Item>
        <Button className='boton-enviar-formulario' htmlType='submit'>
          Enviar
        </Button>
      </Form>
    </div>
  );
}
