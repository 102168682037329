import React from 'react'
import Nav from "./../Componentes/Nav";
import "./index.css";
import Home7 from "./Home7";


export default function SocialListening() {
  return (
    <div className='contenedor-social' id="inicio">
        <Nav/>

        <div className="titulosubtituloboton">
        <div className="titulo-pagina">Social Listening</div>

        <div className="subtitulo-pagina-analiticaAvanzada">
       <strong> Monitoreo en tiempo real</strong> y geolocalización de conversaciones en redes sociales para diversas aplicaciones.         </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
        <strong>Análisis cuantitativo </strong>del impacto de acciones de comunicación en redes. Evaluación de campañas a partir de métricas de volumen, engagement y viralidad.        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
        <strong>Aplicación de modelos analíticos. </strong>Análisis cualitativo de sentimientos (ira, confianza, alegría, tristeza, sorpresa, etc.), preocupaciones y polaridad de los contenidos. </div>
      </div>
      

      <div className="contenedor-cartas-consultoria2">
      <img src='https://qsocialnow.com/static/Imagenes/compurobot.png'  className='imagen-socialListening-computadora'/>
        <div className="titulosubtituloboton" style={{marginTop:'5%'}}>
          <div className="titulo-pagina-consultoria">
          La información necesaria
          </div>

          <div className="subtitulo-pagina-consultoria">
          Trabajamos con <strong>dashboards dinámicos y customizables</strong> según las necesidades del cliente.
          </div>

          <div className="subtitulo-pagina-consultoria">
          <strong>Identificación de tendencias y preocupaciones en redes sociales. </strong>Relevamiento y seguimiento de volumen de conversaciones y niveles de participación sobre temáticas específicas.
          </div>

          <div className="subtitulo-pagina-consultoria">
          <strong>Envío de informes analíticos personalizados.</strong> Reportes realizados por un equipo de analistas que combinan la lectura de los eventos en la plataforma con análisis del contexto.
          </div>
        </div>
      </div>

      <div className="contenedor-home-cartas" style={{marginBottom:'5%'}}>
      <div className="contenedor-titulo">
        <div className="subtitulo-home2" style={{marginLeft:'25%', marginRight:'25%'}}>
          <strong>Apoyo constante de nuestro equipo técnico y de analistas</strong>
        </div>
      </div>

      <div className="contenedor-cartas-home2">
        
        <div className="carta-social">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Visualization.png' style={{width:'30%', marginLeft:'35%', marginTop:'20%'}}/>
          <div className="carta-contenido">
            <strong>Detección de crisis.</strong> <br/><br/>
          Análisis de contenidos en tiempo real detectando volúmenes extraordinarios y polaridad negativa sobre el cliente.
          </div>
        </div>

        <div className="carta-social">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Research%201.png' style={{width:'30%', marginLeft:'35%', marginTop:'20%'}}/>
          <div className="carta-contenido">
            <strong>Envío de alertas personalizadas. </strong><br/><br/>
            Servicio de envío al correo electrónico del cliente con términos que considere relevantes.          </div>
        </div>

        <div className="carta-social">
          <img src='https://qsocialnow.com/static/Imagenes/Consulting%201.png' style={{width:'30%', marginLeft:'35%', marginTop:'20%'}}/>
          <div className="carta-contenido">
            <strong>Detección de influenciadores y contenidos más virales.</strong><br/><br/>
            Análisis y seguimiento de autores con más engagement y viralidad según intereses del cliente.          </div>
        </div>

                
      </div>

      <div className="contenedor-titulo">
        <div className="subtitulo-home2" style={{marginLeft:'25%', marginRight:'25%'}}>
          <strong>Recursos aplicados a las necesidades del cliente</strong>
        </div>
      </div>

      <div className="contenedor-cartas-home2">
        
        <div className="carta-social">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Visualization.png' style={{width:'30%', marginLeft:'35%', marginTop:'20%'}}/>
          <div className="carta-contenido">
            <strong>Generación de informes automatizados. </strong> <br/><br/>
            Envío de informes automáticos generados en la plataforma detallando polaridad, sentimientos, contenidos, influencers, franja horaria, alcance e impresiones de los contenidos.          </div>
        </div>

        <div className="carta-social">
          <img src='https://qsocialnow.com/static/Imagenes/Data%20Research%201.png' style={{width:'30%', marginLeft:'35%', marginTop:'20%'}}/>
          <div className="carta-contenido">
            <strong>Generación de informes elaborados por equipo de analistas. </strong><br/><br/>
            Análisis de datos y elaboración de conclusiones en base a equipo de especialistas.
            </div>
                    </div>

        <div className="carta-social">
          <img src='https://qsocialnow.com/static/Imagenes/Consulting%201.png' style={{width:'30%', marginLeft:'35%', marginTop:'20%'}}/>
          <div className="carta-contenido">
            <strong>Elaboración de informes cuantitativos y cualitativos.</strong><br/><br/>
            Reportes personalizados a demanda del cliente elaborado por equipo de analistas.        </div>
        </div>

                
      </div>

      
    </div>

      <Home7/>
    </div>
  )
}
