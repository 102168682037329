import React from 'react'
import Nav from "./../Componentes/Nav";
import "./index.css";
import Home7 from "./Home7";



export default function Programmatic() {
  return (
    <div className='contenedor-programatic' id="inicio">
        <Nav/>

        <div className="titulosubtituloboton">
        <div className="titulo-pagina-marketindigital">
          <strong>Programmatic <br/>
Advertising</strong>
        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
        Es un sistema <strong>automatizado de compra y segmentación de publicidad</strong> en tiempo real, basada en modelos de AI <strong>(Artificial Intelligence).</strong> 
        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
        <strong>Consta de tres pilares: </strong>plataformas DSP (compra de publicidad), plataformas SSP (venta de publicidad) y plataformas DMP (relevamiento de audiencias). 
        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
        <strong>La tecnología Programmatic</strong> se utiliza para realizar segmentaciones muy específicas,  <strong>optimizando tiempo y recursos económicos.  </strong>      </div>
      </div>

      <div className="contenedor-home5-programatic">
        <div className="titulo-home5">¿Cómo funciona?</div>

        <div className="carta-reputacion">
          <div className="texto-cartareputacion">
          Para que haya una conexión entre los anunciantes y los medios se hace necesario todo un ecosistema intermedio, compuesto por la agencia de medios digitales, el Trading Desk, el DSP, el SSP, el AdExchange, los AdServer, el Data Provider, los DMP y los AdNetwork.
            </div>
         
        </div>

        <img src='https://qsocialnow.com/static/Imagenes/Arrow.png' style={{marginTop:'2%', marginBottom:'2%'}}/>

        <div className="carta-reputacion">
          <div className="texto-cartareputacion">
          QsocialNow, a partir de sus tecnologías, se ocupa de la estrategia y la planificación de medios, interpretando las necesidades del cliente y participando en el proceso enfocado a la compra.            </div>
         
        </div>

     

        <img src='https://qsocialnow.com/static/Imagenes/Arrow.png' style={{marginTop:'2%', marginBottom:'2%'}}/>

        <div className="carta-reputacion">
          <div className="texto-cartareputacion">
          Al mismo tiempo trabajamos como Trading Desk y con nuestra tecnología realizamos las compras de publicidad programática en varios DSPs simultáneamente, unificamos el reporting e integramos datos de terceros, entre otros. El DSP es la tecnología que hace posible la puja/compra al anunciante.            </div>
         
        </div>

        
      </div>

      <div className="contenedor-cartas-programatic">

        <div className="contenedor-3-cartas-capacitacion1">
            <div className="carta-capacitacion">
                <img src='https://qsocialnow.com/static/Imagenes/Database%201.png' style={{width:'40%'}} />
                <div >
                  <div className='contenedor-texto-carta-programatic'>
                  El SSP es la tecnología que permite a los medios de comunicación poner a la venta sus espacios publicitarios y así monetizar su inventario.                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Growth%20Chart%201.png' style={{width:'40%'}} />
                <div >
                <div className='contenedor-texto-carta-programatic'>
                  El AdExchange es el lugar en el que se produce la puja, donde anunciantes y medios confluyen para llevar a cabo la subasta.                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Organizing%201.png' style={{width:'40%'}} />
                <div >
                <div className='contenedor-texto-carta-programatic'>
                  El AdServer es la tecnología que permite servir los anuncios/espacios publicitarios una vez cerrada la puja y que se encarga de la medición.                  </div>
                </div>
            </div>

        </div>


           <div className="contenedor-3-cartas-capacitacion">
            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Data%20Frequency%20Table%201.png' style={{width:'40%'}} />
                <div >
            
                <div className='contenedor-texto-carta-programatic'>
                  El Data Provider o proveedor de datos es el encargado de vender una serie de datos a los anunciantes para que éstos puedan enriquecer sus campañas.                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Automation%201.png' style={{width:'40%'}} />
                <div >
           
                <div className='contenedor-texto-carta-programatic'>
                  Los DMP son las plataformas que permiten al anunciante gestionar los datos, las cookies, las audiencias para llegar a su target más fácilmente y buscar audiencias similares.                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Data%20Synchronization%201.png' style={{width:'40%'}} />
                <div >
         
                <div className='contenedor-texto-carta-programatic'>
                  Los AdNetworks son unos intermediarios de los medios, los encargados de interpretar las necesidades del medio y vender su publicidad de forma eficiente.                  </div>
                </div>
            </div>
        </div>

      
        

      </div>
      
      <Home7/>
    </div>
  )
}
