/** @format */

import React from "react";
import Nav from "../Componentes/Nav";
import Home7 from "./Home7";

export default function ComunicacionDirecta() {
  return (
    <div className="contenedor-comunicacionDirecta" id="inicio">
      <Nav />
      <div className="titulosubtituloboton">
        <div className="titulo-pagina">
          Comunicación <br /> Directa
        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
          <strong>QSocialNow </strong> dispone de tecnologías para desplegar{" "}
          <strong> acciones y campañas uno a uno </strong>con el objetivo de
          llegar de forma directa y con un mensaje personalizado.
          <strong>
            {" "}
            La comunicación directa se articula con las acciones de marketing
            digital de Google y las redes sociales{" "}
          </strong>{" "}
          para generar e identificar usuarios y sus{" "}
          <strong> intereses y preocupaciones.</strong>
        </div>
      </div>

      <div className="contenedor-imagenes-texto">
        <div className="imagen-texto-comunicacion">
          <img src='https://qsocialnow.com/static/Imagenes/imagen54.png' style={{ width: "50%" }} />
          <div className="contenedor-texto-comunicacion">
            <div className="titulo-texto-comunicacion">Mensajes IVR</div>
            <div className="subtitulo-texto-comunnicacion">
              Son grabaciones de voz que se envían a teléfonos móviles o fijos
              para promocionar y difundir productos, servicios, personas y
              marcas.
            </div>
          </div>
        </div>

        <div className="imagen-texto-comunicacion2">
          <div className="contenedor-texto-comunicacion2">
            <div className="titulo-texto-comunicacion">Whatsapp</div>
            <div className="subtitulo-texto-comunnicacion">
              Trabajamos con nuestros clientes en la generación de comunidades
              de intereses y grupos de simpatizantes hacia personas o marcas.
              También realizamos envío masivo de mensajes y contenido
              multimedia.
            </div>
            <div className="subtitulo-texto-comunnicacion">
              Para empresas u organizaciones generamos WhatsApp for Business
              para profesionalizar la atención uno a uno de los usuarios a
              través de teleoperadores o bots, incluyendo la gestión de los
              contactos a través de un CRM.
            </div>
          </div>

          <img src='https://qsocialnow.com/static/Imagenes/Group%2055.png' style={{ width: "50%" }} />
        </div>

        <div className="imagen-texto-comunicacion2">
          <img src='https://qsocialnow.com/static/Imagenes/Group%2056.png' style={{ width: "50%" }} />
          <div className="contenedor-texto-comunicacion3">
            <div className="titulo-texto-comunicacion">Email marketing</div>
            <div className="subtitulo-texto-comunnicacion">
              Desarrollamos contenidos y campañas de correo masivo para nuestros
              clientes. Gestionamos y administramos sus audiencias y hacemos un
              seguimiento intensivo de cada uno de los envíos para conocer
              clics, apertura e ingreso a web o redes sociales desde este canal.
            </div>
          </div>
        </div>

        <div className="imagen-texto-comunicacion2">
          <div className="contenedor-texto-comunicacion2">
            <div className="titulo-texto-comunicacion">Telegram</div>
            <div className="subtitulo-texto-comunnicacion">
              Aunque tiene menos uso que Whatsapp, Telegram se está convirtiendo
              en un canal de recepción de contenidos temáticos y reúne usuarios
              muy fieles a marcas o personas. Desde QSocialNow desarrollamos
              estrategias y acciones para que nuestros clientes tengan una
              presencia destacada en Telegram a través de envío de mensajes,
              bots y canales de difusión.
            </div>
          </div>

          <img src='https://qsocialnow.com/static/Imagenes/Group%2057.png' style={{ width: "45%" }} />
        </div>

        <div className="imagen-texto-comunicacion2">
          <img src='https://qsocialnow.com/static/Imagenes/Group%2058.png' style={{ width: "50%" }} />
          <div className="contenedor-texto-comunicacion3">
            <div className="titulo-texto-comunicacion">SMS</div>
            <div className="subtitulo-texto-comunnicacion">
              Generamos campañas personalizadas de envío de mensajes de texto a
              teléfonos celulares. Cada envío cuenta con contenido medible para
              conocer las acciones de los destinatarios una vez recibido el
              mensaje.
            </div>
          </div>
        </div>

        <div className="imagen-texto-comunicacion2">
          <div className="contenedor-texto-comunicacion2">
            <div className="titulo-texto-comunicacion">CATI</div>
            <div className="subtitulo-texto-comunnicacion">
              Se trata de un servicio de promoción de personas, productos y
              servicios a cargo de un operador humano. También puede ser
              utilizado para encuestas telefónicas. Desde nuestro call center
              contamos con un equipo especializado para el desarrollo de estas
              acciones de comunicación directa.
            </div>
          </div>

          <img src='https://qsocialnow.com/static/Imagenes/Group%2059.png' style={{ width: "45%" }} />
        </div>
      </div>

      <Home7 />
    </div>
  );
}
