/** @format */

import React, { useRef, useEffect } from 'react';

import "./index.css";
import Nav from "./../Componentes/Nav";
import Home7 from "./Home7";

export default function Mexico() {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      // Establecer el tiempo de inicio en segundos
      videoRef.current.currentTime = 3;
    }
  }, []);


  return (
    <div className="contenedor-mexico" id="inicio">
      <Nav />
      <div className="titulosubtituloboton">
        <div className="titulo-pagina-mexico">
          Elecciones <br /> México 2024
        </div>

        <div className="subtitulo-pagina-mexico">
          Aplicación de <strong> Big Data Predictiva </strong> basada en decenas de capas de  <strong> datos
          que abarcan todo el territorio de México </strong> a nivel manzana, sección,
          municipio, estado y país.
        </div>
      </div>
      <div className="cinta-mexico">
        <div className="titulo-mexico">Un servicio imprescindible</div>
        <div className="subtitulo-mexico">
          Para consultores y equipos de campaña para delinear y prever los
          escenarios que acompañarán las elecciones presidenciales y los más de
          2.500 comicios que elegirán senadores, diputados, gobernadores y
          alcaldes en todo México.
        </div>
      </div>

       <div>
      <video
        ref={videoRef}
        src='https://qsocialnow.com/static/Imagenes/QSN_Mexico.mp4'
        controls
        autoPlay
        muted
        loop
        type="video/mp4"
        style={{ width: '76%', borderRadius: '3rem', marginTop: '4%', marginLeft: '12%' }}
      />
    </div>

      <div  className="gif">
        <img src='https://qsocialnow.com/static/Imagenes/QSocialNow-MEXICO-elecciones-INFO_1.gif' style={{width:'95%', borderRadius:'3rem', marginTop:'4%', marginBottom:'5%'}}/>
      </div>

<Home7/>

    </div>
  );
}
