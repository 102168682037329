/** @format */

import React from "react";
import Nav from "./../Componentes/Nav";
import Home7 from "./Home7";

export default function BigDataPredictiva() {
  return (
    <div className="contenedor-bigdata" id="inicio">
      <Nav />

      <div className="titulosubtituloboton">
        <div className="titulo-pagina-bigdata">
          <strong>Big Data predictiva</strong>
        </div>

        <div className="subtitulo-pagina-bigdata">
          La aplicación de tecnologías de{" "}
          <strong> Big Data e Inteligencia Artificial </strong>
          permiten desarrollar modelos predictivos sobre el{" "}
          <strong> comportamiento social, político y electoral</strong> con
          índices de acierto <string> superiores al 80%.</string>
        </div>
        <div className="subtitulo-pagina-bigdata">
          Asimismo, <strong> nuestras herramientas </strong>incluyen la
          posibilidad de accionar sobre{" "}
          <strong> diversos segmentos y micro-segmentos </strong> que integran
          las audiencias a las que nuestros clientes proyectan{" "}
          <strong> alcanzar con sus mensajes.</strong>
        </div>
      </div>

      <div className="cinta-bigdata">
      <strong> Basándonos en información </strong> de carácter público, <strong> QSocialNow</strong> desarrolló sus
        propios <strong>algoritmos a partir de la combinación</strong> de una multiplicidad de
        variables que, correlacionadas, permiten <strong> predecir con niveles crecientes</strong>
        de probabilidad tanto las <strong>tendencias electorales </strong>como los cambios que se
        registran en a nivel de las <strong>percepciones sociales</strong> y <strong>la identificación de
        las nuevas demandas.</strong>
      </div>



      <div className="contenedor-cartas-bigdata">
      <div className="titulo-home5-bigdata"><strong>Nuestro enfoque predictivo, asociado a la gestión de gobiernos y procesos electorales, abarca cuatro dimensiones:</strong></div>

      <div className="contendor-cartas-opinionpublica">
            <div className="carta-opinionpublica2">
                <img src='https://qsocialnow.com/static/Imagenes/icons.png' />
                <div className="subtitulo-carta-opinionpublica">La predicción, considerando la referencia de un partido, coalición o candidato en términos de la fidelidad del voto y su crecimiento o decrecimiento.</div>
            </div>
            <div className="carta-opinionpublica2">
                <img src='https://qsocialnow.com/static/Imagenes/icons%20%281%29.png' />
                <div className="subtitulo-carta-opinionpublica">La predicción sobre las percepciones ciudadanas que forman los estados de opinión que los distintos sectores de la sociedad se van formando acerca de la oferta electoral en relación con sus demandas.</div>
            </div>
            <div className="carta-opinionpublica2">
                <img src='https://qsocialnow.com/static/Imagenes/icons%20%282%29.png' />
                <div className="subtitulo-carta-opinionpublica">La predicción de escenarios sociales y políticos y la evaluación de su impacto positivo, negativo o neutral para los partidos, coaliciones o candidatos.</div>
            </div>
            <div className="carta-opinionpublica2">
                <img src='https://qsocialnow.com/static/Imagenes/icons%20%283%29.png' />
                <div className="subtitulo-carta-opinionpublica">La identificación de perfiles votantes considerando sus intereses y emociones, la anticipación sobre las posibles motivaciones de voto y la receptividad en cada segmento de la población ante los contenidos y propuestas ofrecidas por los partidos, coaliciones o candidatos.</div>
            </div>
        </div>
      </div>

      <img src='https://qsocialnow.com/static/Imagenes/Illustrations.png' className="imagen-opinionpublica"/>


      <div className="titulo-home5-bigdata"><strong>El estudio de correlaciones de variables, en el marco del modelo multicausal, permitirá identificar, para cada período analizado, los factores que explican como tendencias:</strong></div>


    <div className="contenedor-cartas-bigdatapredictiva">
        <div className="carta-bigdatapredictiva">
            <div>
            El posible <strong>crecimiento/decrecimiento del voto.</strong>
            </div>
            <img src='https://qsocialnow.com/static/Imagenes/Mask%20group%20%281%29.png' className="imagen-marketingDigital"/>
        </div>

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Mask%20group%20%282%29.png' className="imagen-marketingDigital"/>
            <div>
            <strong> La identificación territorial </strong> de las tendencias en cada caso.
            </div>
            
        </div>

        <div className="carta-bigdatapredictiva">
            <div>
            La mutación que, en términos probabilísticos, tiene la estructura del voto en un contexto determinado: <strong>partidos y candidatos que crecen, partidos y candidatos que decrecen </strong>y el vínculo entre ambos fenómenos.
            </div>
            <img src='https://qsocialnow.com/static/Imagenes/Mask%20group%20%283%29.png' className="imagen-marketingDigital"/>
        </div>

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Mask%20group%20%284%29.png' className="imagen-marketingDigital"/>
            <div>
            La correlación entre el <strong> mapa electoral y las tendencias del voto,</strong> por un lado, y el mapa de las percepciones ciudadanas, por el otro.
            </div>
            
        </div>

        <div className="carta-bigdatapredictiva">
            <div>
            La identificación de la multiplicidad de causas que actúan sobre las <strong> percepciones ciudadanas y que explican un comportamiento coincidente, divergente, contradictorio</strong> y/o asincrónico respecto a las preferencias del voto.
            </div>
            <img src='https://qsocialnow.com/static/Imagenes/Mask%20group%20%285%29.png' className="imagen-marketingDigital"/>
        </div>


    </div>

    <Home7/>


    </div>
  );
}
