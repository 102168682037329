/** @format */

import React from "react";
import Nav from "./../Componentes/Nav";
import './index.css'

export default function Capacitacion() {
  return (
    <div className="contenedor-capacitacion" id="inicio">
      <Nav />

      <div className="titulosubtituloboton">
        <div className="titulo-pagina">Capacitación</div>

        <div className="subtitulo-pagina-analiticaAvanzada">
          <strong> En QSocialNow estamos interesados en que nuestros clientes y sus
          equipos crezcan.</strong> Estamos convencidos que la comunicación es un campo
          que requiere alta calificación e innovación permanente. Por eso
          brindamos talleres y capacitación a equipos de comunicación de alto
          rendimiento.
        </div>
      </div>

      <div className="contenedor-cartas-capacitacion">

        <div className="contenedor-3-cartas-capacitacion1">
            <div className="carta-capacitacion">
                <img src='https://qsocialnow.com/static/Imagenes/Report%20Computer%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                  <div className="titulo-carta-capacitacion"> <strong>
                  Taller de capacitación sobre Analítica Avanzada
                  </strong>
                  </div>
                  <div>
                  Curso sobre cómo interpretar los modelos de analítica avanzada desarrollados con inteligencia artificial.
                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Arrow%204.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Taller de capacitación en Big Data predictiva
                  </strong>
                  </div>
                  <div>
                  Curso sobre los alcances y elo uso de las herramientas predictivas desarrolladas por QSocialNow en el campo de la comunicación social y política.
                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Web%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong> 
                  Taller de capacitación en marketing digital y redes sociales
                  </strong>
                  </div>
                  <div>
                  Curso sobre lineamientos básicos de gestión de redes sociales y aspectos principales de pautado.
                  </div>
                </div>
            </div>

        </div>


           <div className="contenedor-3-cartas-capacitacion">
            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Graph%20Rise%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Taller de capacitación en marketing digital y redes sociales avanzado
                  </strong>
                  </div>
                  <div>
                  Curso sobre los tipos de contenidos y manejo de audiencias.
                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Analysis%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Capacitación en Social Listening
                  </strong>
                  </div>
                  <div>
                  Taller sobre configuración, gestión e interpretación de datos cuanti y cualitativos en la herramienta de monitoreo de redes sociales de QSocialNow.
                  </div>
                </div>
            </div>

            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Idea%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Capacitación sobre Campañas de Conversión
                  </strong>
                  </div>
                  <div>
                  Taller sobre estrategias y lineamientos para la generación de leads y manejo de audiencias.
                  </div>
                </div>
            </div>
        </div>

      
        <div className="contenedor-3-cartas-capacitacion2">
            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Planning%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Taller de Producción de Contenidos
                  </strong>
                  </div>
                  <div>
                  Capacitación para un óptimo desarrollo de contenidos multiplataforma.
                  </div>
                </div>
            </div>
            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Web%20Analytic%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Taller de capacitación sobre Analítica Avanzada
                  </strong>
                  </div>
                  <div>
                  Formación avanzada para análisis y mejora de la perfomance de contenidos pagos en redes sociales.
                  </div>
                </div>
            </div>
            <div className="carta-capacitacion">
            <img src='https://qsocialnow.com/static/Imagenes/Mobile%20Report%201.png' style={{width:'20%'}} />
                <div className="contenedor-texto-carta-capacitacion">
                <div className="titulo-carta-capacitacion"> 
                  <strong>
                  Taller de Narrativa Digital y Gestión de Comunidades
                  </strong>
                  </div>
                  <div>
                  Se exploran las nuevas formas de narrar en lo digital y se piensan las redes sociales como espacio de experimentación y generación de contenido e interacción en un sentido prospectivo.
                  </div>
                </div>
            </div>
        </div>

      </div>

 
    </div>
  );
}
