/** @format */

import React from "react";
import Nav from "../Componentes/Nav";

export default function Consultoria() {
  return (
    <div className="contenedor-consultoria" id="inicio">
      <Nav />
      <div className="titulosubtituloboton">
        <div className="titulo-pagina">Soporte y Consultoría</div>

        <div className="subtitulo-pagina-analiticaAvanzada">
          Contamos con un equipo de{" "}
          <strong> más de 30 profesionales especializados</strong> para
          garantizar el{" "}
          <strong> soporte y el mejor servicio a nuestros clientes.</strong>
          Asesoramos sobre el uso y{" "}
          <strong>
            {" "}
            aplicación eficiente de nuestras tecnologías y herramientas.
          </strong>
        </div>
      </div>

      <div className="contenedor-cartas-consultoria">
        <div className="titulosubtituloboton">
          <div className="titulo-pagina-consultoria">
            Diagnóstico del ecosistema digital
          </div>

          <div className="subtitulo-pagina-consultoria">
            Es un servicio de análisis integral de la gestión digital del
            cliente, incluyendo su evaluación en relación a las buenas prácticas
            recomendadas para cada red social. Permite diagnosticar cómo
            funciona, en cantidad y en calidad, el desempeño digital del cliente
            y construir un cuadro FODA (fortalezas, oportunidades, debilidades y
            amenazas) que sirva como base para trazar objetivos de mejoras.
          </div>
        </div>
        <img src='https://qsocialnow.com/static/Imagenes/image%2032.png' style={{ width: "50%" }} />
      </div>

      <div className="contenedor-cartas-consultoria2">
        <img src='https://qsocialnow.com/static/Imagenes/image%205.png' style={{ width: "50%" }} />
        <div className="titulosubtituloboton">
          <div className="titulo-pagina-consultoria">
            Plan de Metas Digitales
          </div>

          <div className="subtitulo-pagina-consultoria">
            Proyección de metas cuantitativas y cualitativas en plazos de 60, 90
            y 180 días; para incrementar cantidad de seguidores y tasas de
            engagement, optimizar publicaciones y los contenidos.
          </div>
        </div>
      </div>

      <div className="contenedor-cartas-consultoria2">
        <div className="titulosubtituloboton">
          <div className="titulo-pagina-consultoria">
            Asistencia al equipo de marketing digital del cliente
          </div>
          <div className="subtitulo-pagina-consultoria">
            Asesoramiento permanente, seguimiento de equipos y optimización de
            campañas.
          </div>
        </div>
        <img src='https://qsocialnow.com/static/Imagenes/image%206.png' style={{ width: "50%" }} />
      </div>
    </div>
  );
}
