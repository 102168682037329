import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Componentes/Footer";
import Home from "./Contenedores/Home";
import QuienesSomos from "./Contenedores/QuienesSomos";
import Mexico from "./Contenedores/Mexico";
import AnaliticaAvanzada from "./Contenedores/AnaliticaAvanzada";
import ComunicacionDirecta from "./Contenedores/ComunicacionDirecta";
import OpinionPublica from "./Contenedores/OpinionPublica";
import Consultoria from "./Contenedores/Consultoria";
import Capacitacion from "./Contenedores/Capacitacion";
import BigDataPredictiva from "./Contenedores/BigDataPredictiva";
import MarquetingDigital from "./Contenedores/MarquetingDigital";
import Programmatic from "./Contenedores/Programmatic";
import SocialListening from "./Contenedores/SocialListening";
import {MdReadMore} from 'react-icons/md'
import {IoAnalyticsOutline} from 'react-icons/io5'
import {FaRegComments, FaAssistiveListeningSystems} from 'react-icons/fa'
import {FaPeopleGroup} from 'react-icons/fa6'
import {HiOutlineClipboardDocumentList} from 'react-icons/hi2'
import {GiDiploma} from 'react-icons/gi'
import { PiMegaphoneDuotone } from 'react-icons/pi';
import { FloatButton, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import TagManager from "react-gtm-module";
import Policies from "./Componentes/Policies";

const tagManagerArgs = {
  gtmId: 'GTM-MV5V2HW2',
  events: {
  sendUserInfo: 'userInfo'
  }
  }
  TagManager.initialize(tagManagerArgs)
function App() {
  return (
    
    <div className="App">
        <FloatButton.Group
      trigger="hover"
      className="floatbutton grupo-float"
      icon={<MdReadMore />}
      
    >
      <Tooltip title='Social Listening' placement="left">
      <Link to="/socialListening">
        <FloatButton.BackTop  visibilityHeight={0} className="floatButton" icon={<FaAssistiveListeningSystems style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>
      </Tooltip>

      <Tooltip title='Marketing Digital' placement="left">
      <Link to="/marketingDigital">
        <FloatButton.BackTop visibilityHeight={0}  className="floatButton" icon={<PiMegaphoneDuotone style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>
      </Tooltip>

      <Tooltip title='Programmatic Advertising' placement="left">
      <Link to="/programatic">
        <FloatButton.BackTop visibilityHeight={0}  className="floatButton" icon={<IoAnalyticsOutline style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>
      </Tooltip>

      <Tooltip title='Comunicación directa' placement="left">
      <Link to="/comunicacionDirecta">
        <FloatButton.BackTop visibilityHeight={0}  className="floatButton" icon={<FaRegComments style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>
      </Tooltip>

      <Tooltip title='Consultoría' placement="left">
      <Link to="/consultoria">
        <FloatButton.BackTop visibilityHeight={0}  className="floatButton" icon={<HiOutlineClipboardDocumentList style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>
      </Tooltip>

      
      <Tooltip title='Opinión Pública' placement="left">
      <Link to="/opinionPublica">
        <FloatButton.BackTop visibilityHeight={0}  className="floatButton" icon={<FaPeopleGroup style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>
      </Tooltip>

            
      <Tooltip title='Capacitación' placement="left">
      <Link to="/capacitacion">
        
        <FloatButton.BackTop visibilityHeight={0}  className="floatButton" icon={<GiDiploma style={{color:'white'}}/>} style={{marginBottom:'0.5rem', backgroundColor:'#FF5301'}}/>
      </Link>


      </Tooltip>

    </FloatButton.Group>


    {/* <FloatButton.Group shape="circle" className="floatbutton grupo-float-wsp" >
      <Link to="https://wa.me/qr/7AA2QOSH34FTE1" target="_blank" style={{marginBottom:'1rem'}} ><FloatButton style={{marginBottom:'1rem', backgroundColor:'#25D366!important'}} icon={<BsWhatsapp />} className="Whatsapp" /></Link>
    </FloatButton.Group>
     */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quienesSomos" element={<QuienesSomos />} />
        <Route path="/mexico" element={<Mexico />} />
        <Route path="/analiticaAvanzada" element={<AnaliticaAvanzada />} />
        <Route path="/comunicacionDirecta" element={<ComunicacionDirecta />} />
        <Route path="/opinionPublica" element={<OpinionPublica />} />
        <Route path="/consultoria" element={<Consultoria />} />
        <Route path="/capacitacion" element={<Capacitacion />} />
        <Route path="/bigdataPredictiva" element={<BigDataPredictiva />} />
        <Route path="/marketingDigital" element={<MarquetingDigital />} />
        <Route path="/programatic" element={<Programmatic />} />
        <Route path="/socialListening" element={<SocialListening />} />
        <Route path="/politicas-y-privacidad" element={<Policies/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
