/** @format */

import React from "react";
import Nav from "../Componentes/Nav";


export default function QuienesSomos() {
  return (
    <div className="contenedor-quienesSomos" id="inicio">
      <Nav />
      <div className="titulosubtituloboton">
        <div className="titulo-pagina">Quienes somos</div>

        <div className="subtitulo-pagina-quienessomos">
          <strong>Somos una compañía de Big Data fundada en Argentina.</strong>
          <br />
          <br />
          Desde QSocial acompañamos a aquellas organizaciones y personas que
          quieren transformar y potenciar su vinculación y comunicación con sus
          audiencias.
        </div>
      </div>

      <div className="contenedor-foto-texto-quienessomos">
        <img src='https://qsocialnow.com/static/Imagenes/grupo1.png'  className="imagen-quienesSomos"/>
        <div>
          <div className="titulo-pagina2">Nuestra misión es</div>

          <div className="subtitulo-pagina-quienessomos2">
            desarrollar y aplicar las nuevas tecnologías para superar con éxito
            los desafíos planteados por la revolución digital en la gestión de
            problemáticas sociales y políticas. Con más de una década de
            desarrollo,{" "}
            <strong>
              {" "}
              somos pioneros en Argentina y en la región. <br /> <br />{" "}
              QsocialNow está formada por un equipo de más de 30 profesionales{" "}
            </strong>{" "}
            (cientistas de datos, ingenieros en sistemas, físicos, matemáticos,
            semiólogos, sociólogos, politólogos, psicólogos, comunicadores,
            especialistas en marketing)
          </div>
        </div>
      </div>

      <div className="contenedor-texto-quienessomos">
        <div className="titulo-home5">Nuestros equipos</div>

        <div className="subtitulo-quienessomos">
          Participan activamente de los desarrollos tecnológicos, en los últimos
          años centrados en Big Data predictivo y en la aplicación de
          Inteligencia Artificial para el análisis del comportamientos sociales
          y políticos, además de desarrollar estrategias de campañas y la
          implementación de acciones de marketing micro-segmentadas.
        </div>
      </div>

      <div className="contenedor-home5">
        <div className="titulo-home5">Reputación</div>

        <div className="carta-reputacion">
          <div className="texto-cartareputacion">
            QSocialNow has established a technological and methodological
            platform that allows for commercial expansion. This business plan is
            being executed with success as seen in the company´s 500 percent
            increase in client base and market presence, especially in Latin
            America.
            </div>
            <div className="subtitulo-cartareputacion"><img src='https://qsocialnow.com/static/Imagenes/cio.png' style={{width:'5%'}}/> CioApplications (USA)</div>
         
        </div>

        <img src='https://qsocialnow.com/static/Imagenes/Arrow.png' style={{marginTop:'2%', marginBottom:'2%'}} />

        <div className="carta-reputacion">
          <div className="texto-cartareputacion">
          La plataforma argentina QSocialNow fue destacada como una de las 10 mejores soluciones de analítica basada en Big Data por la revista especializada en tecnología y negocios CIO Application de Silicon Valley.
            </div>
            <div className="subtitulo-cartareputacion"><img src='https://qsocialnow.com/static/Imagenes/infobae.png' style={{width:'10%'}}/> Infobae (Argentina)</div>
         
        </div>

     

        <img src='https://qsocialnow.com/static/Imagenes/Arrow.png' style={{marginTop:'2%', marginBottom:'2%'}}/>

        <div className="carta-reputacion">
          <div className="texto-cartareputacion">
          QSocial Now é uma ferramenta imprescindível para as organizações e pessoas que precisem traçar estratégias bazadas em indicadores precisos e dado qualitativos. Também pode ser utilizada por o poder publico, instituições financeiras, empresas de tecnologia, cadeias menoristas, industrias e grandes eventos.
            </div>
            <div className="subtitulo-cartareputacion"><img src='https://qsocialnow.com/static/Imagenes/oglobo.jpg' style={{width:'8%'}}/> O GLOBO (Brasil)</div>
         
        </div>

        
      </div>
    </div>
  );
}
