import React from 'react'
import './index.css'
import {TfiEmail} from 'react-icons/tfi'
import {SlLocationPin} from 'react-icons/sl'
import { PiInstagramLogoThin } from "react-icons/pi";
import { BsFacebook, } from "react-icons/bs";
import {RiTwitterXFill} from 'react-icons/ri'
import FormularioContacto from './FormularioContacto'

import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='contenedor-footer'>
  
      <img src='https://qsocialnow.com/static/Imagenes/logo_black.png' className='logo-footer-qsocial'/>

      <div className='contenedor-3filas-footer'>
      <div className='contenedor-primerafila'>
      <div className='contenedor-iconos-footer'>
      <div className='fila-iconos-footer'><TfiEmail/><div>contacto@qsocialnow.com</div></div>
      {/* <div className='fila-iconos-footer'><BsWhatsapp/><div>+54 9 297 429-2246</div></div>
      <div className='fila-iconos-footer'><SlLocationPin style={{fontSize:'20px'}}/><div>Juncal 1311, Piso 7. CABA 1062 Argentina.</div></div> */}
      </div>

      <div className='contenedor-iconos-nav-footer'>
      <Link to={'https://www.instagram.com/qsocialnow'} className='icono-nav-footer'><div ><PiInstagramLogoThin/></div></Link>
      <Link to={'https://www.facebook.com/qsocialnow'} className='icono-nav-footer'><div ><BsFacebook/></div></Link>
      <Link to={'https://twitter.com/qsocialnow'} className='icono-nav-footer'><div ><RiTwitterXFill/></div></Link>
      </div>
      </div>

    <div className='mapa-footer'>
  
    <FormularioContacto/>
    </div>

    {/* <div className='ubicacion-footer'>
        Ubicación
        <Mapa/>
    </div> */}
    </div>

    <div className='piepagina'>
    © 2023 QSocialNow Todos los derechos reservados.
    </div>

    </div>
  )
}
