/** @format */

import React from "react";
import Nav from "./../Componentes/Nav";
import Home7 from "./Home7"


export default function OpinionPublica() {




  return (
    <div>
      <div className="contenedor-opinionpublica" id="inicio">
        <Nav />
        <div className="titulosubtituloboton">
          <div className="titulo-pagina-marketindigital">Opinión Pública</div>

          <div className="subtitulo-pagina-analiticaAvanzada">
            <strong> Contamos con tecnologías y con profesionales altamente calificados
            para el desarrollo de estudios de opinión pública.</strong> Aseguramos la
            calidad durante todo el proceso teniendo en cuenta los <strong> instrumentos
            de recolección de datos, ejecución y procesamiento </strong> y presentación de
            resultados.
          </div>
        </div>



        <div className="titulo-home5-opinionpublica"><strong>Estudios cuantitativos</strong></div>

        <div className="contendor-cartas-opinionpublica">
            <div className="carta-opinionpublica">
                <img src='https://qsocialnow.com/static/Imagenes/icons.png' />
                <div className="titulo-carta-opinionpublica"><strong>Estudios de Opinión Pública mediante IVR.</strong></div>
                <div className="subtitulo-carta-opinionpublica">Encuestas telefónicas de voz grabada en base a formularios aprobados por el cliente.</div>
            </div>
            <div className="carta-opinionpublica">
                <img src='https://qsocialnow.com/static/Imagenes/icons%20%281%29.png' />
                <div className="titulo-carta-opinionpublica"><strong>Estudios de Opinión Pública mediante CATI.</strong></div>
                <div className="subtitulo-carta-opinionpublica">Encuestas telefónicas de voz grabada en base a formularios aprobados por el cliente.</div>
            </div>
            <div className="carta-opinionpublica">
                <img src='https://qsocialnow.com/static/Imagenes/icons%20%282%29.png' />
                <div className="titulo-carta-opinionpublica"><strong>Estudios de caso.</strong></div>
                <div className="subtitulo-carta-opinionpublica">Desarrollo de encuestas a través de redes sociales con recompensas para los participantes desde el perfil de QSocial Now.</div>
            </div>
        </div>


        <div className="titulo-home5-opinionpublica2"><strong>Estudios cualitativos</strong></div>
       
        <div className="contendor-cartas-opinionpublica">
            <div className="carta-opinionpublica2">
            <img src='https://qsocialnow.com/static/Imagenes/icons.png' />
                <div className="titulo-carta-opinionpublica"><strong>Focus group</strong></div>
                <div className="subtitulo-carta-opinionpublica">Grupos focales para relevar percepciones, aspectos cualitativos de personas, productos, servicios y/o coyunturas.</div>
            </div>
            <div className="carta-opinionpublica2">
            <img src='https://qsocialnow.com/static/Imagenes/icons%20%281%29.png' />
                <div className="titulo-carta-opinionpublica"><strong>Entrevistas en profundidad</strong></div>
                <div className="subtitulo-carta-opinionpublica">Personalizadas y coordinadas por un profesional especializado en estudios cualitativos. Se pueden implementar en forma presencial o mediante salas virtuales (Zoom, Skype, etc.)</div>
            </div>
            <div className="carta-opinionpublica2">
            <img src='https://qsocialnow.com/static/Imagenes/icons%20%282%29.png' />
                <div className="titulo-carta-opinionpublica"><strong>Estudios de caso</strong></div>
                <div className="subtitulo-carta-opinionpublica">Desarrollo de investigaciones específicas acorde a los intereses del cliente.</div>
            </div>
            <div className="carta-opinionpublica2">
                <img src='https://qsocialnow.com/static/Imagenes/icons%20%283%29.png' />
                <div className="titulo-carta-opinionpublica"><strong>Observatorios</strong></div>
                <div className="subtitulo-carta-opinionpublica">Monitoreo e investigaciones cualitativas basadas en el análisis de klas conversaciones públicas en redes sociales.</div>
            </div>
        </div>



        <img src='https://qsocialnow.com/static/Imagenes/Group%2091%20%281%29.png' className="imagen-opinionpublica"/>
      </div>
      <Home7 />
    </div>
  );
}
