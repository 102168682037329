/** @format */

import React from "react";
import Nav from "./../Componentes/Nav";
import "./index.css";
import Home7 from "./Home7";

export default function MarquetingDigital() {
  return (
    <div className="contenedor-marketinDigital" id="inicio">
      <Nav />

      <div className="titulosubtituloboton">
        <div className="titulo-pagina-marketindigital">
          <strong>Marketing Digital</strong>
        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
          <strong>Trabajamos en la implementación de campañas en redes sociales y Google</strong>
          brindando tecnología y con un equipo de expertos que asiste de forma
          permanente a nuestros clientes.
        </div>

        <div className="subtitulo-pagina-analiticaAvanzada">
        <strong>Administramos la inversión publicitaria de forma eficiente</strong> y
          analizamos en tiempo real la performance de los anuncios para su
          optimización.
        </div>
      </div>


      <div className="contenedor-cartas-marketing">
     

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%285%29.png' className="imagen-marketingDigital"/>
            <div>
            <strong> Branding </strong>
            </div>
            
        </div>

        <div className="carta-bigdatapredictiva">
            <div>
            <strong> Generación de contenidos para redes sociales. </strong> <br/>Implementación de campañas publicitarias en Facebook, Instagram, Twitter y Google Ads (Search, Display y YouTube).
            </div>
            <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%284%29.png' className="imagen-marketingDigital"/>
        </div>

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%286%29.png' className="imagen-marketingDigital"/>
            <div>
            La correlación entre el <strong> mapa electoral y las tendencias del voto,</strong> por un lado, y el mapa de las percepciones ciudadanas, por el otro.            </div>
        </div>

        <div className="carta-bigdatapredictiva">
            <div>
            <strong>Gestión de cuentas y perfiles.  </strong>          </div>
            <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%281%29.png' className="imagen-marketingDigital"/>
        </div>

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%287%29.png' className="imagen-marketingDigital"/>
            <div>
            <strong> Gestión de ecosistema digital  </strong>              </div>
            
        </div>

        <div className="carta-bigdatapredictiva">
            <div>
            <strong> Revisión y optimización de canales para generar tráfico calificado,</strong>  convertir visitas a leads, calificarlos y generar audiencias. Nuestro trabajo hace foco en redes sociales, web, landing page, identidad de marca y posicionamiento en buscadores.            </div>
            <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%283%29.png' className="imagen-marketingDigital"/>
        </div>

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%288%29.png' className="imagen-marketingDigital"/>
            <div>
            <strong>  Conversión       </strong>     </div>
            
        </div>

        <div className="carta-bigdatapredictiva">
            <div>
            <strong>  Desarrollo de campañas, contenidos y plataformas </strong>para la generación de base de datos.
            <strong> Creación de audiencias </strong>a partir de base de datos del cliente o generadas por campañas publicitarias digitales.            </div>
            <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%282%29.png' className="imagen-marketingDigital"/>
        </div>

        <div className="carta-bigdatapredictiva2">
        <img src='https://qsocialnow.com/static/Imagenes/Nueva%20carpeta/Mask%20group%20%289%29.png' className="imagen-marketingDigital"/>
            <div>
            <strong>  Análisis y evaluación del buyer journey</strong> para optimizar la generación de leads.            </div>
            
        </div>
    </div>
    <Home7/>
        </div>
  );
}
