import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import { PiInstagramLogoThin, PiTwitterLogo} from 'react-icons/pi';
import { BsFacebook } from 'react-icons/bs';
import { Button } from 'antd';
import {RiTwitterXFill} from 'react-icons/ri'


export default function Nav() {
  const location = useLocation();

  // Define la lista de URLs donde se debe mostrar el LogoBlanco
  const whiteLogoURLs = ['/bigdataPredictiva', '/mexico'];
  
  // Verifica si la URL actual está en la lista de URLs con LogoBlanco
  const shouldShowWhiteLogo = whiteLogoURLs.some(url => location.pathname.includes(url));



  return (
    <div className='contenedor-nav'>
      <Link to='/'>
        <img
          src={shouldShowWhiteLogo ? 'https://qsocialnow.com/static/Imagenes/logo-blanco.png' : 'https://qsocialnow.com/static/Imagenes/Logo.png'}
          className='logo-qsocial'
          alt='Logo'
        />
      </Link>
      <div className='contenedor-links-nav'>
        <Link to='/quienesSomos' className='link'>
          <div style={{ color: 'white' }} className='quienesSomosBoton'>
            Quienes somos
          </div>
        </Link>
  
        <Button className='boton-acceso' disabled>
          Acceso Plataforma
        </Button>

        <div className='contenedor-iconos-nav-footer'>
      <Link to={'https://www.instagram.com/qsocialnow'} className='icono-nav-footer2'><div ><PiInstagramLogoThin/></div></Link>
      <Link to={'https://www.facebook.com/qsocialnow'} className='icono-nav-footer2'><div ><BsFacebook/></div></Link>
      <Link to={'https://twitter.com/qsocialnow'} className='icono-nav-footer2'><div ><RiTwitterXFill/></div></Link>
      </div>
      </div>
    </div>
  );
}
